<template>
  <div class="container">
    <van-nav-bar :title="$t('page.team')">
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
    </van-nav-bar>
    <div class="container_top">
      <h1>{{$t('teamReport.title')}}</h1>
      <p>{{$t('teamReport.subTitle')}}</p>
    </div>
    <div class="container_middle">
      <div class="userinfo">
        <div class="contentLeft_img">
          <img
            class="user_img"
            src="../../assets/Img/myImg/MyOne.png"
            alt=""
            srcset=""
            v-if="!MemberInformation.userimg"
          />
          <img
            v-else
            :src="'https://shopee8899.com' + MemberInformation.userimg"
            class="user_img"
            alt=""
          />
        </div>
        <div class="contentLeft_text">
          <div>
            {{
              MemberInformation.username
                ? MemberInformation.username
                : $t("newAdd.noLoginUserName")
            }}
          </div>
          <div class="contentLeft_Vip">
            vip{{ MemberInformation.level ? MemberInformation.level + 1 : 1 }}
          </div>
          <div>
            id: &nbsp;{{ MemberInformation.id ? MemberInformation.id : "" }}
          </div>
        </div>
      </div>
      <div class="money_balance">
        <div class="balanceCase">
          <p>{{ TeamList.data.total_num || 0 }}</p>
          <p>{{ $t("add.Cumulativeinvitation") }}</p>
        </div>
        <div class="balanceCase hb"></div>
        <div class="balanceCase">
          <p>{{ TeamList.data.now }}</p>
          <p>{{ $t("add.Validinvitation") }}</p>
        </div>
        <div class="balanceCase hb"></div>
        <div class="balanceCase">
          <!-- <p>{{ `${verConfig.moneyType}${TeamList.data.total_money}` }}</p> -->
          <p>{{ TeamList.data.total_money ? $money(TeamList.data.total_money) : 0 }}</p>
          <p>{{ $t("add.Accumulatedrebate") }}</p>
        </div>
      </div>
    </div>

    <div class="middle_img">
      <img src="../../assets/Img/myImg/site_22.5624e11d.png" alt="" />
    </div>

    <div class="container_list">
      <div
        v-for="(item, index) in TeamList.data.level"
        class="list_li"
        :key="index"
      >
        <p style="color: #3b9359; margin-bottom: 5vw">
          {{$t("teamReport.taskTitle",{num: item.num})}}
        </p>
        <div class="list_con">
          <p style="color: #999">
            {{$t("teamReport.bonus")}}:{{ item.money }}
            <span
              v-if="TeamList.data.now < item.num"
              style="text-indent: 3em; display: inline-block"
              >{{ TeamList.data.now }}/{{ item.num }}</span
            >
            <span
              v-if="TeamList.data.now >= item.num"
              style="text-indent: 3em; display: inline-block"
              >{{ item.num }}/{{ item.num }}</span
            >
          </p>
          <div
            class="btn G"
            @click="getMoney(index)"
            v-if="!item.is_get && TeamList.data.now >= item.num"
          >
            Get
          </div>
          <div
            class="btn U"
            v-else-if="!item.is_get && TeamList.data.now < item.num"
          >
            {{$t("teamReport.Unfinished")}}
          </div>
          <div class="btn Ged" v-else-if="item.is_get">{{$t("teamReport.Getted")}}</div>
        </div>
      </div>
    </div>
    <!-- <Tabbar active="team" /> -->
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import { Toast, NavBar, Icon } from "vant";
import verConfig from "@/utils/verConfig";
export default {
  components: { Tabbar, [NavBar.name]: NavBar, [Icon.name]: Icon },
  data() {
    return {
      verConfig,
      MemberInformation: {
        name: "",
        code: "",
        money: "0",
        url: "",
      },
      TeamList: {
        data: {
          level: [],
          now: 0,
          status: 1,
          total_money: "0.00",
          total_num: 0,
        },
      },
    };
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      // if (this.$cookie.get('userInfo')) {
      //   this.MemberInformation = JSON.parse(this.$cookie.get('userInfo'))
      // }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          Toast.clear();
          this.MemberInformation = res;
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    // 获取团队信息
    getTeam() {
      if (localStorage.getItem("userInfo")) {
        this.$api.getUserShareInfo();
      }
    },

    // 领取奖励
    getMoney(code) {
      this.$api.getUserShareInfo(
        { act: "get" },
        function (res) {
          Toast(res);
        },
        () => {
          this.TeamList.data.level[code].is_get = 1;
          this.TeamList = JSON.parse(JSON.stringify(this.TeamList));
        }
      );
    },
  },
  created() {
    // this.getUserInfo();
    this.getTeam();
  },
  mounted() {
    this.getUserInfo();
    if (JSON.parse(localStorage.getItem("teamList"))) {
      this.TeamList = JSON.parse(localStorage.getItem("teamList"));
    } else {
      Toast("Not logged in!");
    }
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}
* {
  margin: 0;
  padding: 0;
}
.container {
  @background: var(--nav-background);
  /deep/ .van-nav-bar {
    background: @background;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  .container_top {
    height: 40.667vw;
    // background: url(../../assets/Img/myImg/bg_01.6e175c41.png) no-repeat top;
    background: @background;
    background-size: 100%;
    padding: 6vw 5vw;
    color: white;
  }
  .container_middle {
    .userinfo {
      .flex(start);
      .user_img {
        width: 15vw;
        border-radius: 10vw;
      }
      margin-bottom: 5vw;
      .contentLeft_text {
        margin-left: 10px;
        .contentLeft_Vip {
          background: rgba(0, 0, 0, 0.1);
          text-align: center;
          border-radius: 20px;
        }
      }
    }
    background: #fff;
    color: @Color;
    padding: 5.333vw 0 4vw 3vw;
    width: 90%;
    margin: 0 auto;
    border-radius: 1.333vw;
    box-shadow: 0 0 2.667vw rgba(0, 0, 0, 10%);
    position: relative;
    z-index: 2;
    top: -27vw;
    .money_balance {
      .flex(space-around);
      text-align: center;
      .hb {
        height: 10vw;
        width: 1px;
        background: rgb(190, 190, 190);
      }
    }
  }
  .middle_img {
    width: 90vw;
    margin: 0 auto;
    img {
      width: 100%;
    }
    margin-top: -20vw;
    margin-bottom: 5vw;
  }
  .container_list {
    .list_li {
      background-color: rgb(243, 243, 243);
      width: 85vw;
      margin: 0 auto;
      padding: 2vw 3vw;
      box-shadow: 0 0 2.667vw rgba(0, 0, 0, 10%);
      border-radius: 1.333vw;
      .list_con {
        .flex(space-between);
        .btn {
          border: 1px solid;
          border-radius: 10vw;
          padding: 1vw 2vw;
          color: #999;
        }
        .G {
          color: @Color;
          border-color: @Color;
        }
      }
      margin-bottom: 5vw;
    }
  }
}
</style>
